<template>
  <div class="lottery-index">
    <van-nav-bar
      class="van-nav-bar--fixed"
      title="历史开奖"
      left-arrow
      right-text="年份"
      @click-left="onClickLeft"
      @click-right="showPopup()"
    ></van-nav-bar>

    <!--<div class="m-scene-vote"> -->
    <div>
      <div class="customfixed">
        <div class="sticky-body">
          <div class="m-tabs-years">
            <div class="m-tabs-years-bd">
              <span
                v-for="item in lotto_list"
                :key="item.id"
                :class="lotto_id == item.id ? 'act' : ''"
                @click="setLotto(item.id)"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <!--
                    <div class="m-tabs-years">
                            <div class="m-tabs-years-bd">
                                <span v-for="item in year_list" :key="item.id" :class="year_id==item.id ? 'act':''" @click="setYear(item.id)">{{ item.name }}年</span>
                            </div>
                     </div>
  -->
        </div>
      </div>
    </div>

    <div class="van-tabs van-tabs--line">
      <div class="van-tabs__content van-tabs__content--animated">
        <div
          class="van-tabs__track"
          style="
            transform: translate3d(0%, 0px, 0px);
            transition-duration: 0.3s;
          "
        >
          <div role="tabpanel" class="van-tab__pane-wrapper">
            <div class="van-tab__pane">
              <div class="historical-list">
                <div class="van-pull-refresh">
                  <div
                    class="van-pull-refresh__track"
                    style="transition-duration: 0ms"
                  >
                    <div class="van-pull-refresh__head"></div>
                    <div class="head-find">
                      <div class="van-row">
                        <div class="van-col van-col--10">
                          {{ year_name }}年历史开奖记录
                        </div>
                        <div class="van-col van-col--14">
                          <div class="right-find flex-end">
                            <span class="border center" @click="onSort()">{{
                              sort == 1 ? "升序" : "降序"
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="body-list">
                      <div role="feed" class="van-list">
                        <van-list
                          v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad"
                        >
                          <div
                            class="body-list-item"
                            v-for="items in list"
                            :key="items.id"
                          >
                            <div class="hd-text">
                              <div class="van-row">
                                <div class="van-col van-col--16">
                                  第{{ items.journal_name }}期最新开奖结果
                                </div>
                                <div class="van-col van-col--8">
                                  <span>{{ items.datetime }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="border-box">
                              <div
                                class="border-box-body"
                                @click="goToUrl(items.id)"
                              >
                                <div class="border-box-body-bd">
                                  <div class="van-row">
                                    <div
                                      class="van-col van-col--3"
                                      v-for="(item, index) in items.lottery"
                                      :key="item.id"
                                    >
                                      <div class="connect" v-if="index == 6">
                                        <i
                                          class="van-icon van-icon-plus"
                                          style="color: rgb(172, 172, 172)"
                                        ></i>
                                      </div>

                                      <div class="icon-text" v-else>
                                        <div>
                                          <span
                                            :class="
                                              item.bose_name == '绿波'
                                                ? 'slot-icon bg-amage-3'
                                                : item.bose_name == '红波'
                                                ? 'slot-icon bg-amage-1'
                                                : item.bose_name == '蓝波'
                                                ? 'slot-icon bg-amage-2'
                                                : 'slot-icon'
                                            "
                                            ><span>{{
                                              item.haoma_name
                                            }}</span></span
                                          >
                                        </div>
                                        <div class="slot-text">
                                          {{ item.animal_name
                                          }}<span>/{{ item.five_name }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </van-list>

                        <div class="van-list__placeholder"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <van-picker
        title="年份"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      show: false,
      sort: 0,
      five_id: 0,
      year_id: 1,
      year_name: "2024",
      lotto_id: 2,
      active: "kaijiang",
      list: [],
      year_list: [],
      lotto_list: [],
      loading: false,
      finished: false,
      iserror: false,
      refreshing: false,
      columns: [],
      page: 0,
      message: "",
      value: "",
      iscolour: 0,
    };
  },
  mounted() {
    this.lotto_id = parseInt(this.$route.query.lotto_id)
      ? parseInt(this.$route.query.lotto_id)
      : 2;
    //this.active='picture';
    //this.category_id= parseInt(this.$route.query.id) ? parseInt(this.$route.query.id)  : 1;
    //this.getData();
  },
  methods: {
    onConfirm(value) {
      //Toast(`当前值：${value.id}`);
      this.year_name = value.text;
      this.year_id = value.id;
      this.show = false;
      this.finished = true;
      this.page = 1;
      this.list = [];
      this.getData();
    },
    onSort() {
      this.finished = true;
      this.page = 1;
      this.list = [];
      if (this.sort) {
        this.sort = 0;
      } else {
        this.sort = 1;
      }
      this.getData();
    },
    onCancel() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    onClickLeft() {
      // 返回上一级页面
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({ path: "/kaijiang" });
    },
    goToUrl(id) {
      //console.log('/kaijiang/show?id='+id);
      this.$router.push({
        path: "/kaijiang/show?id=" + id + "&lotto_id=" + this.lotto_id,
      });
    },
    setLotto(id) {
      this.finished = true;
      this.page = 1;
      this.list = [];
      this.lotto_id = id;
      this.getData();
    },
    setYear(id) {
      this.finished = true;
      this.page = 1;
      this.list = [];
      this.year_id = id;
      this.getData();
    },
    onLoad() {
      this.page++;
      this.getData();
    },
    getData() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        this.$http
          .get(
            "api/kaijiang/list?sort=" +
              this.sort +
              "&page=" +
              this.page +
              "&five_id=" +
              this.five_id +
              "&lotto_id=" +
              this.lotto_id +
              "&year_id=" +
              this.year_id
          )
          .then(function (response) {
            if (response.data.code == "ok") {
              if (response.data.data.list.data.length < 10) {
                this.finished = true;
              } else {
                this.finished = false;
              }

              if (!response.data.data.list.data.length) {
                this.finished = true;
              } else {
                for (let i = 0; i < response.data.data.list.data.length; i++) {
                  response.data.data.list.data[i]["lottery"].splice(6, 0, {});
                  this.list.push(response.data.data.list.data[i]);
                }

                this.year_list = response.data.data.year_list;
                this.lotto_list = response.data.data.lotto_list;
                this.columns = response.data.data.year_list;
              }

              this.loading = false;
              // 数据全部加载完成
              if (this.list.length >= response.data.data.list.total) {
                this.finished = true;
              }
              //this.finished = true;
            } else {
              this.iserror = true;
              this.message = response.data.msg;
            }
            this.loading = false;
            Toast.clear();
            // console.log(this.list);
          });
      }, 500);
    },
  },
};
</script>
<style>
.corpusdetaillist-msg .vant-swiper-wrap {
  margin-top: 50px;
}
.corpus-wrap .search-list {
  margin-bottom: 50px;
}
.van-pull-refresh {
  margin-bottom: 50px;
}
div#app {
  background-color: #ffffff;
}
</style>
